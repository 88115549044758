import axios, { ResponseData } from './base.ts'
import { AxiosPromise } from 'axios'

export function getBaseUrl() {
  return 'http://foodsecurityplatform.com/'
}

// 公共
// 获取公钥
export function getRSAPublicKey(data = {}) {
  return axios.request({
    url: `/admin/getRSAPublicKey`,
    method: 'post',
    data: data,
  })
}
// 登录
export function login(data = {}) {
  return axios.request({
    url: `/admin/login`,
    method: 'post',
    data: data,
  })
}
// 登出
export function logout(data = {}) {
  return axios.request({
    url: `/admin/logout`,
    method: 'post',
    data: data,
  })
}
// 获取当前登录后台用户信息
export function getUserInfo(data = {}) {
  return axios.request({
    url: `/admin/user/getUserInfo`,
    method: 'post',
    data: data,
  })
}
// 修改当前登录用户密码
export function modefyPassword(data = {}) {
  return axios.request({
    url: `admin/user/modifyUserPassword`,
    method: 'post',
    data: data,
  })
}
// 获取省市区列表
export function getRegionTree(data = {}) {
  return axios.request({
    url: `/admin/region/getTree`,
    method: 'post',
    data: data,
  })
}
// 通过子级获取父级id
export function getParentTree(data = {}) {
  return axios.request({
    url: `/admin/region/getParentTree`,
    method: 'post',
    type: 'special',
    data: data,
  })
}

// 小程序用户管理
// 获取用户列表
export function getWechatUserList(data = {}) {
  return axios.request({
    url: `/admin/wechatUser/pageList`,
    method: 'post',
    data: data,
  })
}
// 设置用户为内部员工
export function settingAdmin(data = {}) {
  return axios.request({
    url: `/admin/wechatUser/settingAdmin`,
    method: 'post',
    data: data,
  })
}

// 检测记录
// 获取流水线列表
export function getPipelineList(data = {}) {
  return axios.request({
    url: `/admin/pipeline/page`,
    method: 'post',
    data: data,
  })
}
// 查询样品详情
export function getSampleDetail(data = {}) {
  return axios.request({
    url: `/admin/sample/detail`,
    method: 'post',
    data: data,
  })
}
// 结果判定
export function judgmentResult(data = {}) {
  return axios.request({
    url: `/admin/pipeline/result/judgment`,
    method: 'post',
    data: data,
  })
}
// 查看检测记录流水节点

// 柜机管理
// 获取柜机列表
export function getCabinetList(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/page`,
    method: 'post',
    data: data,
  })
}
// 新增柜机
export function createCabinet(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/create`,
    method: 'post',
    data: data,
  })
}
// 修改柜机
export function updateCabinet(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/update`,
    method: 'post',
    data: data,
  })
}
// 删除柜机
export function delCabinet(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/deleted`,
    method: 'post',
    data: data,
  })
}
// 获取柜机详情
export function getCabinetDetail(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/detail`,
    method: 'post',
    data: data,
  })
}
// 获取柜机系统类型枚举
export function getsystemTypeEnum(data = {}) {
  return axios.request({
    url: data.param
      ? `/admin/cabinet/machineSystem/systemTypeEnum?param=${data.param}`
      : `/admin/cabinet/machineSystem/systemTypeEnum`,
    method: 'get',
    data: data,
  })
}
// 获取柜机类型枚举
export function getCabinetType(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/type`,
    method: 'get',
    data: data,
  })
}
// 获取柜机系统列表
export function getMachineSystemList(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/list`,
    method: 'post',
    data: data,
  })
}
// 获取柜机系统详情
export function getMachineSystemDetail(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/detail`,
    method: 'post',
    data: data,
  })
}
// 创建柜机系统
export function addMachineSystem(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/create`,
    method: 'post',
    data: data,
  })
}
// 修改柜机系统
export function updateMachineSystem(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/update`,
    method: 'post',
    data: data,
  })
}
// 获取芯片类型枚举
export function getChipType(data = {}) {
  return axios.request({
    url: data.param
      ? `/admin/cabinet/machine/chip/type?param=${data.param}`
      : `/admin/cabinet/machine/chip/type`,
    method: 'GET',
    data: data,
  })
}
// 删除柜机系统
export function delMachineSystem(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/deleted`,
    method: 'post',
    data: data,
  })
}
// 初始柜机化芯片状态
export function initCabinetChip(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machine/chip/init`,
    method: 'post',
    data: data,
  })
}

// 获取设备列表
export function getDeviceList(data = {}) {
  return axios.request({
    url: `/admin/device/list`,
    method: 'post',
    data: data,
  })
}
// 补充耗材
export function replenishConsumable(data = {}) {
  return axios.request({
    url: `/admin/device/consumable/replenish`,
    method: 'post',
    data: data,
  })
}
// 系统设备 故障修复
export function fixBug(data = {}) {
  return axios.request({
    url: `/admin/device/bug/fix`,
    method: 'post',
    data: data,
  })
}
// 系统维护
export function maintainSystem(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/maintain`,
    method: 'post',
    data: data,
  })
}

// 模板管理
//  流水线模板
export function getPipelineTemplate(data = {}) {
  return axios.request({
    url: data.param
      ? `/admin/pipeline/template/list?param=${data.param}`
      : `/admin/pipeline/template/list`,
    method: 'get',
    data: data,
  })
}

// 流水模板不查询节点动作
export function getPipelineTemplateList(data = {}) {
  return axios.request({
    url: `/admin/pipeline/template/listOnly`,
    method: 'get',
    data: data,
  })
}

//  设备模板
export function getDeviceTemplate(data = {}) {
  return axios.request({
    url: data.param
      ? `/admin/device/template/list?param=${data.param}`
      : `/admin/device/template/list`,
    method: 'get',
    data: data,
  })
}

//动作模板
export function getActionTemplate(data = {}) {
  return axios.request({
    url: data.param
      ? `/admin/action/template/list?param=${data.param}`
      : `/admin/action/template/list`,
    method: 'GET',
    data: data,
  })
}

// 权限管理 - 用户管理
// 获取后台用户列表
export function getUserList(data = {}) {
  return axios.request({
    url: `/admin/user/pageList`,
    method: 'post',
    data: data,
  })
}
// 新增用户/admin/user/create
export function addUser(data = {}) {
  return axios.request({
    url: `/admin/user/create`,
    method: 'post',
    data: data,
  })
}
// 编辑用户/admin/user/update
export function updateUser(data = {}) {
  return axios.request({
    url: `/admin/user/update`,
    method: 'post',
    data: data,
  })
}
// 重置用户密码
export function resetUserPassword(data = {}) {
  return axios.request({
    url: `/admin/user/resetUserPassword`,
    method: 'post',
    // type:"special",
    data: data,
  })
}
// 打卡上下班
export function updateUserWork(data = {}) {
  return axios.request({
    url: `/admin/user/updateWork`,
    method: 'post',
    type: 'special',
    data: data,
  })
}
// 冻结或解冻用户
export function forbiddenUser(data = {}) {
  return axios.request({
    url: `/admin/user/forbiddenUser`,
    method: 'post',
    type: 'special',
    data: data,
  })
}
// 用户工作状态枚举
export function getSystemWorkStatusType(data = {}) {
  return axios.request({
    url: `/admin/user/systemWorkStatusType`,
    method: 'get',
  })
}

// 权限管理 - 角色管理
// 获取角色列表
export function getRoleList(data = {}) {
  return axios.request({
    url: `/admin/role/list`,
    method: 'get',
    data: data,
  })
}
// 新增角色
export function addRole(data = {}) {
  return axios.request({
    url: `/admin/role/create`,
    method: 'post',
    data: data,
  })
}
// 修改角色
export function updateRole(data = {}) {
  return axios.request({
    url: `/admin/role/update`,
    method: 'post',
    data: data,
  })
}
// 获取角色详情
export function getRoleInfo(data = {}) {
  return axios.request({
    url: `/admin/role/getRoleInfo`,
    method: 'get',
    data: data,
  })
}
// 获取角色菜单权限
export function selectMenuByRoleId(data = {}) {
  return axios.request({
    url: `/admin/menu/selectMenuByRoleId`,
    method: 'post',
    data: data,
  })
}
// 修改角色菜单权限
export function createMenu(data = {}) {
  return axios.request({
    url: `/admin/menu/createMenu`,
    method: 'post',
    data: data,
  })
}

// 权限管理 - 菜单管理
// 获取菜单列表
export function getTreeMenu(data = {}) {
  return axios.request({
    url: `/admin/menu/getTreeMenu`,
    method: 'get',
    data: data,
  })
}
// 创建菜单
export function createTreeMenu(data = {}) {
  return axios.request({
    url: `/admin/menu/create`,
    method: 'post',
    data: data,
  })
}
// 编辑菜单
export function updateTreeMenu(data = {}) {
  return axios.request({
    url: `/admin/menu/update`,
    method: 'post',
    data: data,
  })
}

// 客服问答列表
// 获取客服问答列表
export function getCustomerMsgList(data = {}) {
  return axios.request({
    url: `/admin/customerMsg/pageList`,
    method: 'post',
    data: data,
  })
}
// 新增问答
export function addCustomerMsg(data = {}) {
  return axios.request({
    url: `/admin/customerMsg/create`,
    method: 'post',
    data: data,
  })
}
// 编辑问答
export function updateCustomerMsg(data = {}) {
  return axios.request({
    url: `/admin/customerMsg/update`,
    method: 'put',
    data: data,
  })
}
// 删除问答
export function delCustomerMsg(data = {}) {
  return axios.request({
    url: `/admin/customerMsg/delete`,
    method: 'put',
    data: data,
  })
}

// 样品类型
// 获取样品类型列表
export function getSampleTypeList(data = {}) {
  return axios.request({
    url: `/admin/sample/type/list`,
    method: 'post',
    data: data,
  })
}
// 新增样品类型
export function addSampleType(data = {}) {
  return axios.request({
    url: `/admin/sample/type/add`,
    method: 'post',
    data: data,
  })
}
// 编辑样品类型
export function updateSampleType(data = {}) {
  return axios.request({
    url: `/admin/sample/type/update`,
    method: 'post',
    data: data,
  })
}
// 删除样品类型
export function delSampleType(data = {}) {
  return axios.request({
    url: `/admin/sample/type/delete`,
    method: 'post',
    data: data,
  })
}

// 新增样品检测标准
export function addStandards(data = {}) {
  return axios.request({
    url: `/admin/sample/testing/standards/add`,
    method: 'post',
    data: data,
  })
}
// 编辑样品检测标准
export function updateStandards(data = {}) {
  return axios.request({
    url: `/admin/sample/testing/standards/update`,
    method: 'post',
    data: data,
  })
}
// 删除样品检测标准
export function deleteStandards(data = {}) {
  return axios.request({
    url: `/admin/sample/testing/standards/delete`,
    method: 'post',
    data: data,
  })
}
// 查询列表 样品检测标准
export function getStandards(data = {}) {
  return axios.request({
    url: `/admin/sample/testing/standards/page`,
    method: 'post',
    data: data,
  })
}

// 大屏
// 统计数据
export function getStatistics(data = {}) {
  return axios.request({
    url: `/admin/statistics/data?param=${data.data}`,
    method: 'get',
    type: 'special',
    data: data,
  })
}
// 异常统计数据
export function getAbnormal(data = {}) {
  return axios.request({
    url: `/admin/statistics/abnormal?param=${data.data}`,
    method: 'get',
    type: 'special',
    data: data,
  })
}
// 异常详情
export function getAbnormalDetail(data = {}) {
  return axios.request({
    url: `/admin/statistics/abnormal/detail`,
    method: 'post',
    data: data,
  })
}
// 柜机设备全部归位
export function setAllCabinetDevicesToPosition(data = {}) {
  return axios.request({
    url: ``,
    method: 'post',
    data: data,
  })
}
// 柜机设备单个归位
export function resetSingleCabinetDevice(data = {}) {
  return axios.request({
    url: `/admin/device/homing`,
    method: 'post',
    data: data,
  })
}

// 柜机解锁
export function unlockCabinet(data = {}) {
  return axios.request({
    url: `/admin/cabinet/machineSystem/unlock`,
    method: 'post',
    data: data,
  })
}
